import { onMounted, onUpdated } from 'vue'
import { isEmpty, Logger } from 'zeed'
import type { LoggerInterface } from 'zeed'

const log: LoggerInterface = Logger('use:external-links')

function updateLinks() {
  log('update links')
  const fails: Record<string, number> = {}
  const alias = globalThis.siteConfig?.gotoAlias
  for (const e of document.querySelectorAll('a[href]')) {
    let href = e.getAttribute('href')
    if (href) {
      const isDownload = e.getAttribute('download')

      if (alias != null) {
        const path = href.split('?')[0]
        if (path) {
          const url = alias[path]
          if (url) {
            e.setAttribute('href', url)
            href = url
          }
          else if (path?.startsWith('/goto/')) {
            fails[path] = (fails[path] ?? 0) + 1
          }
        }
      }

      if (/^(?:mailto:|data:|https?:)/.test(href) && !isDownload) {
        e.setAttribute('target', '_blank')
        if (!e.getAttribute('rel'))
          e.setAttribute('rel', 'noopener noreferrer')
        e.classList.add('external')
      }
    }
  }

  if (!isEmpty(fails)) {
    log.warn('Missing alias', fails)
  }
}

/** After page updates checks for potential external links and adds targe=_blank and rel=noopener noreferrer */
export function useExternalLinks() {
  onUpdated(updateLinks)
  onMounted(updateLinks)
}
