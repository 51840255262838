import { ViteSSG } from 'vite-ssg'
import { Logger } from 'zeed'
import type { ViteSSGContext } from 'vite-ssg'
import type { Component } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import type { LoggerInterface } from 'zeed'
import { emitter } from './lib/emitter'
import type { SiteConfig } from './_types'
// import { useSentry } from './lib/sentry'

const log: LoggerInterface = Logger('main-standard')

export function setupMainStandard(opt: {
  siteConfig: SiteConfig
  appComponent: Component
  routes: RouteRecordRaw[]
  setup?: (ctx: ViteSSGContext<true>, siteConfig: SiteConfig) => Promise<void>
}) {
  // Canonical
  for (const r of opt.routes) {
    const canonical = r.path
    if (r.meta == null)
      r.meta = { canonical }
    else
      r.meta.canonical = canonical
  }

  return ViteSSG(
    opt.appComponent,
    {
      routes: opt.routes,

      // Scroll to anchor or top of page
      async scrollBehavior(to, _from, savedPosition) {
        log('scrollBehavior', to, savedPosition)
        if (to.hash?.length > 1) {
          const hash = to.hash.slice(1)
          log('hash', hash)

          const el = document.getElementById(hash) as HTMLElement
          if (el) {
            log('found', el)
            return {
              left: 0,
              top: Math.max(0, el.offsetTop),
              behavior: 'instant',
            }
          }
        }
        log('not found', savedPosition)
        if (savedPosition) {
          return {
            ...savedPosition,
            behavior: 'instant',
          }
        }
        return {
          left: 0,
          top: 0,
          behavior: 'instant',
        }
      },
    },
    async (ctx) => {
      const { router, app, isClient } = ctx // app, isClient, routes, initialState

      // if (isClient && opt.siteConfig.sentryDsn)
      //   useSentry(opt.siteConfig.sentryDsn, app, router)

      app.provide('siteConfig', opt.siteConfig)
      globalThis.siteConfig = opt.siteConfig

      // i18n setup
      const { install } = await import('./i18n')
      const { switchLocale } = await install(ctx, siteConfig)

      // Lazy loading
      if (isClient) {
        emitter.on('didUpdateContent', () => {
          log('didUpdateContent', location.hash)
          const hash = location.hash.slice(1)
          if (hash) {
            const el = document.getElementById(hash)
            if (el) {
              log('scroll into view', hash)
              // el.scrollIntoView({
              //   behavior: 'instant',

              // })
              window.scrollTo({
                behavior: 'instant',
                top: Math.max(0, el.offsetTop),
              })
              return
            }
          }

          log('scroll top')
          // document.body.scrollIntoView({ behavior: 'instant' })
        })
      }

      // custom setup
      if (opt.setup)
        await opt.setup(ctx, siteConfig)

      // install all modules under `modules/`
      // Object.values(import.meta.glob<{ install: UserModule }>('./modules/*.ts', { eager: true })).forEach(i => i.install?.(ctx))

      // Locale depends on path like /de/ or /en/
      router.beforeEach(async (to) => {
        const [_, lang] = (/\/(\w\w)($|\/)/m).exec(to.fullPath) ?? []
        await switchLocale(lang ?? '')
      })
    },
  )
}
